.summaryTable td:first-child {
    padding: 0 10px;
    text-align: inherit;
}

.summaryTable td {
    padding: 0 0 0 10px;
    text-align: right;
    vertical-align: top;
}

.total {
    font-weight: bold;
}

.shippingMethod {
    font-size: .9em;
    margin: 0 0 0 10px;
}