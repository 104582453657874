.quick-order {
  max-width: 100%;
  width: 600px; }

.quickorder-menu .form-control {
  background-color: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none; }

.quickorder-menu input.form-control {
  padding-left: 0;
  font-size: 16px; }

.label-row {
  margin-top: 10px;
  margin-bottom: 20px; }

.entry-label > a {
  text-decoration: underline; }

.quick-order-unauthorized {
  text-align: center; 
  padding:1rem;
}

.unauthorized-label,
.unauthorized-label > a {
  font-size: 16px;
  text-transform: initial; }

.addToCart-button {
  margin-top: 3em; }

/*.quick-order-entry .autocomplete {
    display: none;
}

.quick-order-entry:active .autocomplete {
    display: flex;
}
*/
.quick-order-entry:active .easy-autocomplete-container {
  left: 15px;
  width: calc(100% - 30px); }

.quick-order-entry:active .easy-autocomplete-container ul {
  display: block; }

.quick-order-entry .eac-item {
    cursor: pointer;
}