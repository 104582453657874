/* a:hover {
    cursor: pointer;
} */
/* 
.white {
    background-color: white;
} */
/* 
.product-search-results-top {
    margin-bottom: 1em;
}

.search-app-inner {
    padding-top: 20px;
    padding-bottom: 20px;
}

.search-app-inner h1 {
    margin-top: 0;
}

#combined-search-tabs .tab-content {
    padding-left: 0;
    padding-right: 0;
    border: 0;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0;
    left: 0;
    padding: 0;
    opacity: 0.26;
    z-index: 100;
}

.category-details-wrapper {
    position: absolute;
    left: 6%;
    top: 35%;
    color: white;
    font-size: 16px;
}

.banner-wrapper {
    position: relative;
    margin-bottom: 1em;
}

.banner-wrapper h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .2em;
}

.category-list-pagination-wrapper {
    margin-bottom: 1em;
}

.filter-products-row {
    height: 32px;
}

.js-recommendations-list {
    margin-bottom: 30px;
}

.related-products-wrapper {
    margin-bottom: 30px;
} */

@media (min-width: 992px) {
    .filter-products-row {
        margin-bottom: 1em;
    }
}


@media (max-width: 382px) {
    .hidden-xxs {
        display: none;
    }
}

/* span.filter-products {
    color: #F44D00;
    font-weight: bold;
    text-transform: UPPERCASE;
} */